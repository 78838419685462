<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img
        src="/img/avatars/6.jpg"
        class="img-avatar"
        :alt="$store.getters.email" />
        {{ this.$store.getters.fullname }}
    </template>
    <template slot="dropdown">
      <!-- <b-dropdown-header tag="div" class="text-center"><strong>Account</strong></b-dropdown-header>
      <b-dropdown-item><i class="fa fa-bell-o" /> Updates
        <b-badge variant="info">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-envelope-o" /> Messages
        <b-badge variant="success">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-tasks" /> Tasks
        <b-badge variant="danger">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-comments" /> Comments
        <b-badge variant="warning">{{ itemsCount }}</b-badge>
      </b-dropdown-item> -->
      <b-dropdown-header
        tag="div"
        class="text-center">
        <strong>Тохиргоо</strong>
      </b-dropdown-header>
      <b-dropdown-item :to="{ name: 'profile.change_password' }"><i class="fa fa-key" />Нууц үг солих</b-dropdown-item>
      <!-- <b-dropdown-item><i class="fa fa-wrench" /> Settings</b-dropdown-item>
      <b-dropdown-item><i class="fa fa-usd" /> Payments
        <b-badge variant="secondary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-file" /> Projects
        <b-badge variant="primary">{{ itemsCount }}</b-badge>
      </b-dropdown-item> -->
      <b-dropdown-divider />
      <!-- <b-dropdown-item><i class="fa fa-shield" /> Lock Account</b-dropdown-item> -->
      <b-dropdown-item :to="{ name: 'Logout' }"><i class="fa fa-lock" /> Гарах</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {  }
  }
}
</script>
