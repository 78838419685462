export default {
  items: [
    {
      name: 'Хянах самбар',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      title: true,
      name: 'Үндсэн',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      }
    }
  ]
}
